<template>
    <el-dialog
        :visible.sync="dialogVisible"
        :title="dialogTitle"
        :width="dialogWidth"
        :show-close="false"
        :before-close="beforeClose"
        :close-on-click-modal="false"
        :close-on-press-escape="false"
        @open="setOpen"
    >
        <h3 slot="title" style="text-align: left">{{ dialogTitle }}</h3>
        <!-- 窗体头部 -->
        <el-form
            class="search-box"
            :model="form"
            :inline="false"
            label-position="left"
            label-width="auto"
            style="margin-bottom: 20px"
        >
            <el-row>
                <el-col :span="24">
                    <!-- 一级类别选择器 -->
                    <el-form-item
                        type="flex"
                        label="资质类别："
                        label-position="left"
                        label-align="left"
                        :label-width="'auto'"
                    >
                        <el-select
                            v-model="category"
                            placeholder="请选择"
                            @change="handleCategoryChange"
                        >
                            <el-option
                                v-for="option in categoryOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            ></el-option>
                        </el-select>
                        <!-- 二级类别选择器 -->
                        <el-select
                            style="margin-left: 50px"
                            v-show="!!category"
                            v-model="subCategory"
                            placeholder="请选择"
                            @change="handleSubCategoryChange"
                        >
                            <el-option
                                v-for="option in subCategoryOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            ></el-option>
                        </el-select>
                        <!-- 三级类别选择器 -->
                        <el-select
                            style="margin-left: 50px"
                            v-show="category && subCategory"
                            v-model="threeCategory"
                            placeholder="请选择"
                            @change="handle3CategoryChange"
                        >
                            <el-option
                                v-for="option in threeCategoryOptions"
                                :key="option.value"
                                :label="option.label"
                                :value="option.value"
                            ></el-option>
                        </el-select>

                        <!-- 重置按钮 -->
                        <el-button
                            style="float: right"
                            type="primary"
                            @click="handleReset"
                            >重置</el-button
                        >
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <!-- 窗体体部 -->
        <div class="data-table" v-loading="loadingStatus">
            <el-table
                ref="table"
                :show-header="false"
                :data="tableData"
                :height="tableHeight"
                :row-key="tableRowKey"
                @selection-change="handleSelectionChange"
            >
                <!-- 复选框列 -->
                <el-table-column type="selection" width="55"></el-table-column>
                <!-- 列表字段 -->
                <el-table-column
                    v-for="column in tableColumns"
                    :key="column.prop"
                    :prop="column.prop"
                    :label="column.label"
                    :min-width="column.minWidth"
                ></el-table-column>
            </el-table>
            <el-pagination
                v-if="total > 0"
                :current-page.sync="currentPage"
                :page-size="pageSize"
                :total="total"
                @current-change="handlePageChange"
                layout="prev, pager, next, total, jumper"
                style="margin-top: 20px; text-align: right"
            />
        </div>
        <!-- 窗体底部 -->
        <div slot="footer">
            <el-button @click="dialogVisible = false">取消</el-button>
            <el-button type="primary" @click="handleConfirm"
                >立即查询</el-button
            >
        </div>
    </el-dialog>
</template>
<script>
export default {
    props: {
        // 一级类别选择器的选项
        categoryOptions: {
            type: Array,
            required: true,
        },
        categoryItem: {
            type: Object,
            default: () => {},
        },
        // 二级类别选择器的选项
        // subCategoryOptions: {
        //     type: Array,
        //     default: () => [],
        // },
        // 数据列表的字段
        tableColumns: {
            type: Array,
            required: true,
        },
        // 数据列表的API调用方法，返回Promise对象
        fetchTableData: {
            type: Function,
            required: true,
        },
        fetchSubCategories: {
            type: Function,
            required: true,
        },
        fetch3Categories: {
            type: Function,
            required: true,
        },
        // 已选中的记录，格式为数组
        selectedRows: {
            type: Array,
            default: () => [],
        },
        // 选中状态的标识字段
        selectionKey: {
            type: String,
            default: "id",
        },
        // 窗体标题
        dialogTitle: {
            type: String,
            default: "选择记录",
        },
        // 窗体宽度
        dialogWidth: {
            type: String,
            default: "50%",
        },
        // 窗体高度
        dialogHeight: {
            type: String,
            default: "80%",
        },
        // 每页数据条数
        pageSize: {
            type: Number,
            default: 10,
        },
        // 是否多选
        multiple: {
            type: Boolean,
            default: false,
        },
        // 是否显示序号列
        showIndex: {
            type: Boolean,
            default: true,
        },
        // 是否显示复选框列
        showCheckbox: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            form: {},
            // 窗体是否可见
            dialogVisible: false,
            // 当前选中的一级类别
            category: [],
            // 当前选中的二级类别
            subCategory: [],
            // 当前选中的三级类别
            threeCategory: [],
            // 子类型选项
            subCategoryOptions: [],
            // 三级分类选项
            threeCategoryOptions: [],
            // 数据列表
            tableData: [],
            // 数据列表的总条数
            total: 0,
            // 当前页数
            currentPage: 1,
            // 数据列表的行高
            tableHeight: 500,
            // 当前选中的记录
            selectedRecords: [],
            // 加载中状态
            loadingStatus: false,
            memorySelected: {},
        };
    },
    computed: {
        // 列表每行的row-key属性
        tableRowKey() {
            return this.selectionKey;
        },
    },
    watch: {
        selectedRows: {
            handler(rows) {
                // 更新已选中的记录
                this.selectedRecords = [...rows];
                // 更新选中状态
                this.updateSelection();
            },
            immediate: true,
        },
        categoryItem: {
            handler(e) {
                this.categoryCopy = e.category;
                this.subCategoryCopy = e.subCategory;
                this.qualificationNamesCopy = e.qualificationNames;
                this.memorySelectedCopy = e.memorySelected;
            },
            immediate: true,
            deep: true,
        },
    },
    // ...省略props和data属性
    methods: {
        setOpen() {
            console.log("setOpen...");
            if (this.categoryCopy !== "全部") {
                this.category = this.categoryCopy;
                this.subCategory = this.subCategoryCopy || "";
            }
            this.qualificationNames = this.qualificationNamesCopy || [];
            this.memorySelected = this.memorySelectedCopy || {};

            this.fetchSubCategoriesData();
        },
        beforeClose() {},
        handleConfirm() {
            if (!this.checkSelection()) {
                this.$message.warning(
                    "同种专业,等级不能多选! 请删除原来的选择!"
                );
                return;
            }
            let memorySelectedConcat = [];
            for (let key in this.memorySelected) {
                memorySelectedConcat = [
                    ...memorySelectedConcat,
                    ...this.memorySelected[key],
                ];
            }
            if (memorySelectedConcat.length < 1) {
                this.$message.warning("请选择资质！");
                return;
            }
            this.handleOk();
            this.$emit("confirm", {
                selectedRows: memorySelectedConcat,
                subCategory: this.subCategory,
                memorySelected: this.memorySelected,
            });
        },
        getMemorySelected() {
            let memorySelectedConcat = [];
            for (let key in this.memorySelected) {
                memorySelectedConcat = [
                    ...memorySelectedConcat,
                    ...this.memorySelected[key],
                ];
            }
            return memorySelectedConcat;
        },
        // 类别级联选择器的change事件处理
        handleCategoryChange(value) {
            this.category = value;
            this.subCategory = "";
            this.threeCategory = "";
            this.fetchSubCategoriesData();
            this.fetchData();
        },
        handleSubCategoryChange(value) {
            this.subCategory = value;
            this.threeCategory = "";
            this.fetch3CategoriesData();
            this.fetchData();
        },
        handle3CategoryChange(value) {
            this.threeCategory = value;
            this.fetchData();
        },
        // 查询按钮的click事件处理
        handleQuery() {
            this.fetchData();
        },
        // 分页组件的change事件处理
        handlePageChange(page) {
            this.currentPage = page;
            this.fetchData();
        },
        checkSelection() {
            let majorList = this.getMemorySelected();
            let ret = true;
            majorList.forEach((item, idx, allItems) => {
                let selectable = this.isSelectableMajor({
                    item: item,
                    allItems: allItems,
                });
                if (!selectable) {
                    ret = false;
                }
            });
            return ret;
        },
        // 复选框列的selection-change事件处理
        handleSelectionChange(selection) {
            this.selectedRecords = [...selection];
            this.$nextTick(
                () =>
                    (this.memorySelected[this.currentPage] =
                        this.selectedRecords)
            );
        },
        // 确定按钮的click事件处理
        handleOk() {
            this.$emit("select", this.selectedRecords);
            this.dialogVisible = false;
        },
        open() {
            this.dialogVisible = true;
            this.handleReset();
        },
        // 取消按钮的click事件处理
        handleCancel() {
            this.dialogVisible = false;
        },
        handleReset() {
            this.category = "";
            this.subCategory = "";
            this.memorySelected = {};
            this.fetchData();
        },
        sameMajorDiffLevel(item1, item2) {
            let levelName_1 = item1.level;
            let levelName_2 = item2.level;
            let cate1_1 = item1.cate1;
            let cate1_2 = item1.cate2;
            let cate1_3 = item1.cate3;
            let cate2_1 = item2.cate1;
            let cate2_2 = item2.cate2;
            let cate2_3 = item2.cate3;
            return (
                cate1_1 == cate2_1 &&
                cate1_2 == cate2_2 &&
                cate1_3 == cate2_3 &&
                levelName_1 != levelName_2
            );
        },
        isSelectableMajor({ item, allItems }) {
            let majorList = allItems;
            //同个专业 不同等级
            for (let i = 0; i < majorList.length; i++) {
                if (this.sameMajorDiffLevel(majorList[i], item)) {
                    return false;
                }
            }
            return true;
        },
        // 更新选中状态
        updateSelection() {
            if (!this.multiple && this.selectedRecords.length > 1) {
                this.selectedRecords = [this.selectedRecords[0]];
            }
            this.tableData.forEach((row) => {
                const isSelected = this.selectedRecords.some((record) => {
                    return record[this.selectionKey] === row[this.selectionKey];
                });
                // this.$refs.table.toggleRowSelection(row, isSelected);
            });
        },
        // 查询子类型
        async fetchSubCategoriesData() {
            if (!this.category) {
                this.subCategoryOptions = [];
                return;
            }
            try {
                const response = await this.fetchSubCategories({
                    cate1List: this.category ? [this.category] : [],
                });
                let records = response.data ? response.data : [];
                let subCategories = [];
                (records || []).forEach((item) => {
                    subCategories.push({
                        label: item,
                        value: item,
                    });
                });
                this.subCategoryOptions = subCategories;
            } catch (res) {
                this.subCategoryOptions = [];
            } finally {
            }
        },
        // 查询三级分类
        async fetch3CategoriesData() {
            if (!this.category || !this.subCategory) {
                if (!this.category)
                    this.subCategoryOptions = [];
                if (!this.subCategory)
                    this.threeCategoryOptions = [];    
                return;
            }
            try {
                const response = await this.fetch3Categories({
                    cate1List: this.category ? [this.category] : [],
                    cate2List: this.subCategory ? [this.subCategory] : [],
                });
                let records = response.data ? response.data : [];
                let threeCategories = [];
                (records || []).forEach((item) => {
                    threeCategories.push({
                        label: item,
                        value: item,
                    });
                });
                this.threeCategoryOptions = threeCategories;
            } catch (res) {
                this.threeCategoryOptions = [];
            } finally {
            }
        },
        // 查询数据列表
        fetchData() {
            this.$nextTick(async () => {
                if (this.loadingStatus) return;
                this.loadingStatus = true;
                try {
                    const response = await this.fetchTableData({
                        size: this.pageSize,
                        current: this.currentPage,
                        cate1List: this.category ? [this.category] : [],
                        cate2List: this.subCategory ? [this.subCategory] : [],
                        cate3List: this.threeCategory ? [this.threeCategory] : [],
                    });
                    this.tableData = response.data;
                    this.total = response.total;
                    this.$nextTick(() => {
                        if (this.memorySelected[this.currentPage]) {
                            this.tableData.forEach((item) => {
                                if (
                                    this.memorySelected[this.currentPage].some(
                                        (obj) => obj.id === item.id
                                    )
                                ) {
                                    this.$refs.table.toggleRowSelection(
                                        item,
                                        true
                                    );
                                }
                            });
                        }
                    });
                } catch (res) {
                    this.tableData = [];
                    this.total = 0;
                } finally {
                    this.loadingStatus = false;
                }
            });
        },
    },
};
</script>
<style scoped>
::v-deep .el-button--primary {
    background-color: #007ef2;
    border-color: #007ef2;
}
</style>
