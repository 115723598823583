<template>
    <div>
        <query-form ref="queryForm"
            :fields="fields"
            :hasBtnBar="false"
            :actDisabled="actDisabled"
            @submit="handleQuery"
            @reset="handleReset"
            @customSelect="onCustomSelect"
        ></query-form>

        <QtpSelector
            ref="qtpSelector"
            :categoryOptions="categoryOptions"
            :categoryItem="categoryItem"
            @queryDirect="handleQueryDirect"
        ></QtpSelector>
    </div>
</template>

<script>
import QueryForm from "@/components/query-form";
import QtpSelector from "./selector";

import {
    getCommonQualificationTypes,
    h5PartnerApplyRegion
} from "@/api/aptitude";

export default {
    props: {
        cate1List: {
            type: Array,
            default: () => [],
        },
        qualificationList: {
            type: Array,
            default: () => [],
        },
        actDisabled: {
            type: Boolean,
            default: false,
        }
    },
    components: {
        QueryForm,
        QtpSelector,
    },
    data() {
        return {
            cate2List: [],
            levelList: [],
            categoryOptions: [],
          categoryItem: {},
            provinceOptions: [],
            cityOptions: [],
            regionList: [],
            fields: [
                {
                    label: "资质类别：",
                    field: "category",
                    component: "select",
                    flat: true,
                    options: [                       
                    ],
                    selection: [],
                },
                {
                    label: "资质名称：",
                    field: "qualificationNames",
                    placeholder: "请选择资质包含名称",
                    component: "custom-select",
                    multiple: true,
                    selection: [],
                },
                {
                    label: "企业注册属地：",
                    field: "proAdCode",
                    component: "select",
                    flat: false,
                    placeholder: "请选择省级",
                    options: this.provinceOptions,
                    changeHandler: this.loadCities,
                    extra: [
                        {
                            label: "请选择市级",
                            placeholder: "请选择市级",
                            field: "cityAdCode",
                            component: "select",
                            flat: true,
                            options: this.cityOptions,
                        }
                    ],
                    withQueryBtn: true,
                    withResetBtn: true
                },
            ],
            tableData: [],
        };
    },
    computed: {
    },
    methods: {
        extractProvinceOptions() {
            let options = [];
            (this.regionList || []).forEach(item => {
                let opt = {
                    label: item.name,
                    value: item.adCode
                };
                options.push(opt);
            });
            return options;
        },
        extractCityOptions(proAdCode) {
            let options = [];
            let target = (this.regionList || []).find(item => item.adCode == proAdCode);
            if (target) {                
                (target.child || []).forEach(item => {
                    let opt = {
                        label: item.name,
                        value: item.adCode
                    };
                    options.push(opt);
                });
            }
            return options;
        },
        async loadH5PartnerApplyRegion() {
            const response = await h5PartnerApplyRegion();

            let records = response.data ? response.data : [];
            this.regionList = records;
            this.provinceOptions = this.extractProvinceOptions();
            this.setQueryFormOptions('proAdCode', this.provinceOptions);
        },
        async loadCategoryOptions() {
            const response = await getCommonQualificationTypes();

            let records = response.data ? response.data : [];
            let categories = [];
            let fieldsOptions = [
                {
                    label: "全部",
                    value: "all",
                },
            ];
            (records || []).forEach((item) => {
                fieldsOptions.push({
                    label: item,
                    value: item,
                });
                categories.push({
                    label: item,
                    value: item,
                });
            });
            this.categoryOptions = categories;
            let target = (this.fields || []).find(
                (item) => item.field == "category"
            );
            if (target) {
                target.options = fieldsOptions;
                target.selection = ["全部"];
                this.setQueryFormSelection(target.field, target.selection);
            }
        },
        setQueryFormOptions(field, options) {
            let value = options || [];
            if(this.$refs.queryForm)
                this.$refs.queryForm.setOptions(field, value);
        },
        setQueryFormSelection(field, selection) {
            let value = (selection && selection.length) ? selection[0] : '全部';
            if(this.$refs.queryForm) {
                console.log('setQueryFormSelection-->field=' + field + ', selection=' + selection); 
                this.$refs.queryForm.setSelection(field, value);
            }
        },
        setQueryFormExtraOptions(field, extraField, options) {
            let value = options || [];
            if(this.$refs.queryForm)
                this.$refs.queryForm.setExtraOptions(field, extraField, value);
        },
        loadCities(proAdCode) {
            console.log('l===========' + proAdCode);            
            this.cityOptions = this.extractCityOptions(proAdCode);
            this.setQueryFormExtraOptions('proAdCode', 'cityAdCode', this.cityOptions, 0);
        },
        handleReset() {
            this.cate2List = [];
            this.levelList = [];
            this.categoryItem = {};
            this.$emit("reset");
        },
        handleQuery(orignFormData) {
            let {
                category,
                cityAdCode,
                proAdCode,
                qualificationNames
            } = orignFormData;
            let formData = {};

            formData.cate1List = this.cate1List || [];
            formData.cate2List = this.cate2List || [];
            formData.levelList = this.levelList || [];
            formData.qualificationList = this.qualificationList || [];

            if (category) {
                if (category == '全部')
                    formData.cate1List = [];
                else
                    formData.cate1List = [category];
            }
            if (cityAdCode) {
                formData.cityAdCode = cityAdCode;
            }
            if (proAdCode) {
                formData.proAdCode = proAdCode;
            }
            this.$emit("submit", formData);
        },
        getProCityCodes() {
            let data = {};
            if(this.$refs.queryForm) {
                let formData = this.$refs.queryForm.getFormData();
                if (formData.proAdCode)
                    data.proAdCode = formData.proAdCode;
                if (formData.cityAdCode)
                    data.cityAdCode = formData.cityAdCode;
            }
            return data;
        },
        handleQueryDirect({ selectedRows, subCategory, memorySelected }) {
            console.log("handleQueryDirect->", selectedRows, subCategory);
            if (this.categoryItem) {
                this.categoryItem.subCategory = subCategory;
                this.categoryItem.memorySelected = memorySelected;
            }
            let formData = {};
            let cate1List = [];
            let cate2List = [];
            let levelList = [];
            let qualificationList = [];
            (selectedRows || []).forEach((item) => {
                if (item.cate && cate1List.indexOf(item.cate) == -1)
                    cate1List.push(item.cate);
                if (item.cate2 && cate2List.indexOf(item.cate2) == -1)
                    cate2List.push(item.cate2);
                if (item.level && levelList.indexOf(item.level) == -1)
                    levelList.push(item.level);
                if (item.name && qualificationList.indexOf(item.name) == -1)
                    qualificationList.push(item.name);
            });
            formData.cate1List = cate1List;
            formData.cate2List = cate2List;
            formData.levelList = levelList;
            formData.qualificationList = qualificationList;

            // this.cate1List = cate1List;
            this.cate2List = cate2List;
            this.levelList = levelList;
            // this.qualificationList = qualificationList;

            let { proAdCode, cityAdCode } = this.getProCityCodes();
            if (proAdCode)
                formData.proAdCode = proAdCode;
            if (cityAdCode)
                formData.cityAdCode = cityAdCode;

            this.$emit("submit", formData);
        },
        onCustomSelect(formData) {
            let subCategory = this.categoryItem? this.categoryItem.subCategory : '';
            let memorySelected = this.categoryItem? this.categoryItem.memorySelected : {};
          this.categoryItem = { ...formData };
          this.categoryItem.subCategory = subCategory;
          this.categoryItem.memorySelected = memorySelected;
          
            if (this.$refs.qtpSelector) this.$refs.qtpSelector.openDialog();
        },
    },
    mounted() {
        this.loadCategoryOptions();
        this.loadH5PartnerApplyRegion();
        this.$watch("cate1List", (newValue, oldValue) => {
            console.log(
                `cate1List changed from ${oldValue} to ${newValue}`
            );
            let target = (this.fields || []).find(
                (item) => item.field == "category"
            );
            if (target) {
                target.selection = newValue;
                this.setQueryFormSelection(target.field, newValue);
            }
        });

        this.$watch("qualificationList", (newValue, oldValue) => {
            console.log(
                `qualificationList changed from ${oldValue} to ${newValue}`
            );
            let target = (this.fields || []).find(
                (item) => item.field == "qualificationNames"
            );
            if (target) {
                target.selection = newValue;
            }
        });
    }
};
</script>
