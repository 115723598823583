<template>
    <div>
        <select-dialog
            ref="selectDialog"
            :dialog-title="dialogTitle"
            :tableColumns="columns"
            :tableHeight="350"
            :categoryOptions="categoryOptions"
            :categoryItem="categoryItem"
            :fetchTableData="fetchTableData"
            :fetchSubCategories="fetchSubCategories"
            :fetch3Categories="fetch3Categories"
            :columns="columns"
            :selection-key="selectionKey"
            :selectedRows="selectedRows"
            @confirm="handleConfirm"
        ></select-dialog>
    </div>
</template>

<script>
import SelectDialog from "@/components/select-dialog";
import {
    getCommonQualificationTypes2,
    getCommonQualificationTypes3,
    getCommonQualificationTypeProPage,
} from "@/api/aptitude";

export default {
    name: "QtpSelector",
    components: {
        SelectDialog,
    },
    props: {
        categoryOptions: {
            type: Array,
            default: () => [],
        },
      categoryItem: {
        type: Object,
        default: () => {},
      }
    },
    data() {
        return {
            dialogTitle: "选择资质",
            selectionKey: "selectionKey",
            columns: [{ prop: "name", label: "名称", minWidth: 100 }],
            selectedRows: [],
        };
    },
    computed: {
        selectedRecords() {
            return this.selectedRows.map((row) => row.name).join(",");
        },
    },
  methods: {
        openDialog() {
            this.$refs.selectDialog.open();
        },
        handleConfirm({ selectedRows, subCategory, memorySelected }) {
            this.selectedRows = selectedRows;
            this.$emit('queryDirect', { selectedRows, subCategory, memorySelected });
        },
        // 查询数据列表
        async fetchTableData({ size, current, cate1List, cate2List, cate3List }) {
            // 获取数据的 API 请求
            const response = await getCommonQualificationTypeProPage({
                size,
                current,
                cate1List,
                cate2List,
                cate3List,
            });

            let records = response.data ? response.data.records : [];
            let total = response.data ? response.data.total : 0;
            return {
                data: records,
                total: total,
            };
        },
        // 查询子分类列表
        async fetchSubCategories({ cate1List }) {
            // 获取数据的 API 请求
            const response = await getCommonQualificationTypes2({
                cate1List,
            });

            let records = response.data ? response.data : [];
            return {
                data: records,
            };
        },
        // 查询三级分类列表
        async fetch3Categories({ cate1List, cate2List }) {
            // 获取数据的 API 请求
            const response = await getCommonQualificationTypes3({
                cate1List,
                cate2List,
            });

            let records = response.data ? response.data : [];
            return {
                data: records,
            };
        },
    },
};
</script>
